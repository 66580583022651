import * as Yup from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";
import { SoortElementOptions } from "../../.generated/forms/formstypes";

export const FlattendElement = Yup.object().shape({
  code: Yup.number(),
  naam: Yup.string(),
  soortElement: yupEnum(SoortElementOptions),
  volgnummer: Yup.number(),
  inhoud: Yup.string()
    .nullable()
    .default(null),
  perAdviesAanpasbaar: Yup.boolean()
    .nullable()
    .default(null),
  ParentHoodstukken: Yup.array(Yup.number()).default([]),
  ParentCode: Yup.number().nullable(),
  parentNaam: Yup.string().nullable()
});

export type FlattendElementType = Yup.InferType<typeof FlattendElement>;

export type RapportageElementenType = {
  code: number;
  naam: string;
  soortElement: SoortElementOptions;
  volgnummer: number;
  elementen: RapportageElementenType[];
  inhoud: string | null;
  perAdviesAanpasbaar: boolean | null;
};

export const rapportageElementSchema: Yup.ObjectSchema<RapportageElementenType> = Yup.object().shape({
  code: Yup.number(),
  naam: Yup.string(),
  soortElement: yupEnum(SoortElementOptions),
  volgnummer: Yup.number(),
  elementen: Yup.array<RapportageElementenType>().default([]),
  inhoud: Yup.string()
    .nullable()
    .default(null),
  perAdviesAanpasbaar: Yup.boolean()
    .nullable()
    .default(null)
});

export const rapportageSchema = Yup.object({
  structuurId: Yup.string().required(),
  naam: Yup.string().nullable(),
  volgnummer: Yup.number(),
  elementen: Yup.array(FlattendElement).default([]),
  filteredElementen: Yup.array(FlattendElement).default([]),
  selectedElement: Yup.string()
    .nullable()
    .default(null)
});

export type RapportageState = Yup.InferType<typeof rapportageSchema>;
export type RapportageProps = RapportageState & { setIsDirty: (selected: boolean) => void; voorstel: string | null };
