import { ItemType, MenuConfigType, mapStringToLocalDate } from "adviesbox-shared";
import { KlantnaamReadOnly } from "../../.generated/forms/formstypes";
import { birthdate2age } from "./birthdate-to-age";

export const getAanvragerNaam = (aanvrager: KlantnaamReadOnly): string => {
  const voorletters = aanvrager?.voorletters?.length
    ? `\xa0${aanvrager?.voorletters}`
    : aanvrager?.voornamen
    ? `\xa0${aanvrager.voornamen}`
    : /* istanbul ignore next */ "";

  const voorvoegsel = aanvrager?.voorvoegsel ? `\xa0${aanvrager?.voorvoegsel}` : /* istanbul ignore next */ "";

  const achternaam = aanvrager?.achternaam ? `${aanvrager.achternaam}` : /* istanbul ignore next */ "";

  return `${achternaam},${voorletters}${voorvoegsel}`;
};

export const getAanvragerLeeftijd = (geboorteDatum: string | null): number => {
  return geboorteDatum ? birthdate2age(mapStringToLocalDate(geboorteDatum)) : 0;
}

export const getActivePageTitle = (menuConfig: MenuConfigType[], locationPath: string): string => {
  const collectItems = (items: ItemType[], collected: ItemType[] = []): ItemType[] => {
    items.forEach(item => {
      collected.push(item);
      if (item.items) {
        collectItems(item.items, collected);
      }
    });
    return collected;
  };

  const allItems = menuConfig.flatMap(config =>
    config.lists.flatMap(list =>
      collectItems([list])
    )
  );

  return allItems.find(item => item.link === locationPath)?.title.toString() || "Page name";
};