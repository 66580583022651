/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import classNames from "classnames";
import {
  InkomensanalyseResultaat,
  Inkomensanalyse,
  ToelichtingOptions,
  ToelichtingRegel,
  InkomensanalyseNbiOverzichtJaar
} from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { bedragFormatNull, monthDivision, getConclusieArcering, getMaandlastKeuzeTekst } from "./infra/scenario-utils";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { ScenarioBaseProps } from "./scenario-drawer";
import { getNaam } from "../../../shared/utils/helpers";
import { MaximaleHypotheekTable } from "./tables/maximale-hypotheek";
import { CitationRef, CitationNote, CitationProvider, excludedFootnotes } from "./citation-notes";

export type PensioenDataProps = {
  nbiWeergeven: boolean;
  titel: string;
  conclusie: string;
  inkomensanalyseResultaten: InkomensanalyseResultaat[];
  motiveringInkomensanalyse: Inkomensanalyse | null;
  totaalHuidigBrutoJaarinkomenBedrag: number | null;
  nbi: InkomensanalyseNbiOverzichtJaar[];
  nbiVoetnoot: string;
  inkomensData?: {
    naamPersoon: string;
    werkgeversPensioenAanvrager: number | null;
    werkgeversPensioenPartner: number | null;
    restinkomenAanvrager: number | null;
    restinkomenPartner: number | null;
    beleggingInkomenAanvrager: number | null;
    beleggingInkomenPartner: number | null;
    verhuurInkomenAanvrager: number | null;
    verhuurInkomenPartner: number | null;
    aowInkomenAanvrager: number | null;
    aowInkomenPartner: number | null;
    expirerendeLijfrenteAanvrager: number | null;
    expirerendeLijfrentePartner: number | null;
    totaalInkomen: number | null;
    inkomensverlies: number | null;
  };
  financieleGegevens?: {
    huidigInkomen70Procent: number | null;
    benodigdInkomenHypotheek: number | null;
    toegestaneLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
    werkelijkeBrutoLast: number | null;
    tekortWerkelijk: number | null;
    maximaleHypotheek: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    tekortOpInkomen: number | null;
  };
  jaarlijkseAnalyse?: {
    gewenstInkomen: number | null;
    gewenstInkomenPercentage: number | null;
    maandelijksTekort: number | null;
  };
  voetnoten: ToelichtingRegel[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

export const InkomensanalyseTabelPensioen: React.FC<ScenarioBaseProps & PensioenDataProps> = ({
  conclusie,
  inkomensData,
  financieleGegevens,
  voetnoten,
  nbi,
  nbiVoetnoot,
  nbiWeergeven,
  weergevenOptions,
  onKlantIdChange,
  selectedKlantId,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  controleMaandlast,
  controleMaandlastKeuze,
  maximaleHypotheekWeergeven,
  jaarlijkseAnalyse,
  loading,
  inkomensanalyseResultaten,
  totaalHuidigBrutoJaarinkomenBedrag,
  motiveringInkomensanalyse
}) => {
  const columnStyleLabel = {
    width: "70%"
  };
  const columnStyle = {
    width: "30%",
    textAlign: "right" as const
  };

  const aanvrager =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager1
      : motiveringInkomensanalyse?.aanvrager2;
  const partner =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager2
      : motiveringInkomensanalyse?.aanvrager1;

  return (
    <CitationProvider voetnoten={voetnoten} excludedFootnotes={excludedFootnotes}>
      <div className="container-fluid mt-2 px-2">
        {loading && <CardLoadingSpinner />}
        {weergevenOptions.length > 0 && (
          <Formik initialValues={{ scenarioKlantId: selectedKlantId }} enableReinitialize onSubmit={() => {}}>
            {({ setFieldValue }) => (
              <div className={"col-md-6 pb-3"}>
                <LabeledRadioInput
                  name={"scenarioKlantId"}
                  caption={"Weergeven scenario van"}
                  options={weergevenOptions}
                  onChange={event => {
                    onKlantIdChange(event.target.value);
                    setFieldValue("scenarioKlantId", event.target.value);
                  }}
                  layout={Direction.Horizontal}
                />
              </div>
            )}
          </Formik>
        )}
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
          </colgroup>
          <thead>
            {!loading && inkomensData && (
              <tr>
                <th colSpan={4}>Inkomen bij pensioen van {inkomensData.naamPersoon}</th>
              </tr>
            )}
          </thead>
          <tbody>
            <tr>
              <td>AOW {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.aowInkomenAanvrager, monthDivision)}</td>
            </tr>
            {inkomensData?.beleggingInkomenAanvrager !== 0 && (
              <>
                <tr>
                  <td>
                    Inkomsten uit belegging(en) {getNaam(aanvrager ?? null, "Aanvrager 1")}{" "}
                    <CitationRef type={ToelichtingOptions.Brutering} />
                  </td>
                  <td>{bedragFormatNull(inkomensData?.beleggingInkomenAanvrager, monthDivision)}</td>
                </tr>
              </>
            )}
            {inkomensData?.verhuurInkomenAanvrager !== 0 && (
              <>
                <tr>
                  <td>
                    Inkomsten uit verhuur {getNaam(aanvrager ?? null, "Aanvrager 1")}{" "}
                    <CitationRef type={ToelichtingOptions.Brutering} />
                  </td>
                  <td>{bedragFormatNull(inkomensData?.verhuurInkomenAanvrager, monthDivision)}</td>
                </tr>
              </>
            )}
            {inkomensData?.expirerendeLijfrenteAanvrager !== 0 && (
              <>
                <tr>
                  <td>Expirerende lijfrente {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
                  <td>{bedragFormatNull(inkomensData?.expirerendeLijfrenteAanvrager, monthDivision)}</td>
                </tr>
              </>
            )}
            <tr>
              <td>Werkgeverspensioen {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.werkgeversPensioenAanvrager, monthDivision)}</td>
            </tr>

            {motiveringInkomensanalyse?.aanvrager2 && (
              <>
                <tr>
                  <td>AOW {getNaam(partner ?? null, "Aanvrager 2")}</td>
                  <td>{bedragFormatNull(inkomensData?.aowInkomenPartner, monthDivision)}</td>
                </tr>
                {inkomensData?.beleggingInkomenPartner !== 0 && (
                  <tr>
                    <td>
                      Inkomsten uit belegging(en) {getNaam(partner ?? null, "Aanvrager 2")}{" "}
                      <CitationRef type={ToelichtingOptions.Brutering} />
                    </td>
                    <td>{bedragFormatNull(inkomensData?.beleggingInkomenPartner, monthDivision)}</td>
                  </tr>
                )}
                {inkomensData?.verhuurInkomenPartner !== 0 && (
                  <tr>
                    <td>
                      Inkomsten uit verhuur {getNaam(partner ?? null, "Aanvrager 2")}{" "}
                      <CitationRef type={ToelichtingOptions.Brutering} />
                    </td>
                    <td>{bedragFormatNull(inkomensData?.verhuurInkomenPartner, monthDivision)}</td>
                  </tr>
                )}
                {inkomensData?.expirerendeLijfrentePartner !== 0 && (
                  <tr>
                    <td>Expirerende lijfrente {getNaam(partner ?? null, "Aanvrager 2")}</td>
                    <td>{bedragFormatNull(inkomensData?.expirerendeLijfrentePartner, monthDivision)}</td>
                  </tr>
                )}
                <tr>
                  <td>Inkomen van {getNaam(partner ?? null, "Aanvrager 2")}</td>
                  <td>{bedragFormatNull(inkomensData?.restinkomenPartner, monthDivision)}</td>
                </tr>
                <tr>
                  <td>Werkgeverspensioen {getNaam(partner ?? null, "Aanvrager 2")}</td>
                  <td>{bedragFormatNull(inkomensData?.werkgeversPensioenPartner, monthDivision)}</td>
                </tr>
              </>
            )}
            <tr className="font-weight-bold">
              <td>Inkomen totaal</td>
              <td>{bedragFormatNull(inkomensData?.totaalInkomen, monthDivision)}</td>
            </tr>
            <tr className="table-warning">
              <td>Inkomensverlies</td>
              <td>{bedragFormatNull(inkomensData?.inkomensverlies)}</td>
            </tr>
          </tbody>
        </Table>

        {brutoInkomensWensenWeergeven && (
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <>
                <tr>
                  <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig inkomen</td>
                  <td>
                    {bedragFormatNull(
                      (totaalHuidigBrutoJaarinkomenBedrag || 0) * ((brutoInkomensWensPercentage || 0) / 100),
                      monthDivision
                    )}
                  </td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>Maandelijks tekort aan gewenste inkomsten</td>
                  <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekort || 0))}</td>
                </tr>
              </>
            </tbody>
          </Table>
        )}
        {controleWerkelijkeLastenWeergeven && (
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <>
                <tr>
                  <td>
                    Toegestane last o.b.v. inkomen <CitationRef type={ToelichtingOptions.BrutoNettoTekort} />
                  </td>
                  <td>{bedragFormatNull(financieleGegevens?.toegestaneLast)}</td>
                </tr>
                <tr>
                  <td>Werkelijke bruto last van hypotheek en verpande producten</td>
                  <td>{bedragFormatNull(financieleGegevens?.werkelijkeBrutoLast)}</td>
                </tr>
                <tr className="table-danger">
                  <td>
                    <strong>Tekort o.b.v. werkelijke bruto last</strong>
                  </td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortToegestaneLastBedrag)}</td>
                </tr>
              </>
            </tbody>
          </Table>
        )}
        {controleMaandlast && controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <>
                <tr>
                  <td>
                    Minimaal benodigd inkomen voor uw hypotheek{" "}
                    <CitationRef type={ToelichtingOptions.ToetsrenteFinancieringslast} />
                  </td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].benodigdInkomenBedrag, monthDivision)}</td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>
                    Maandelijks tekort aan inkomsten voor uw hypotheek{" "}
                    <CitationRef type={ToelichtingOptions.Brutering} />
                  </td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortBenodigInkomenBedrag, monthDivision)}</td>
                </tr>
              </>
            </tbody>
          </Table>
        )}

        {maximaleHypotheekWeergeven && <MaximaleHypotheekTable {...inkomensanalyseResultaten?.[0]} />}

        {controleMaandlast && (
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  De maandlast die overblijft voor deze situatie t.o.v. de{" "}
                  {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
                </td>
                <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
              </tr>
            </tbody>
          </Table>
        )}

        <p className="mt-3 px-2">
          {voetnoten?.map(footnote => (
            <CitationNote key={footnote.type} type={footnote.type} text={footnote.toelichting || ""} />
          ))}
        </p>

        {nbiWeergeven && (
          <TableNettoBesteedbaarInkomen
            startDatum={motiveringInkomensanalyse?.startdatum}
            nbi={nbi}
            voetnoot={nbiVoetnoot}
          />
        )}

        {benodigdKapitaalWeergeven && (
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Uw risico o.b.v. uw gewenste inkomen</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{benodigdKapitaalOmschrijving}</td>
                <td>{bedragFormatNull(benodigdKapitaal)}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </CitationProvider>
  );
};
