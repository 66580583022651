import { useRequestInit, ValidatiePopup, BrowserNavigation } from "adviesbox-shared";
import classNames from "classnames";
import { FormikContextType } from "formik";
import React, {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { AppDataContext } from "../../../navigation/appdata-context";
import OpenDossierLogContext from "../../open-dossier-log/open-dossier-log-context";
import { SaveFormContext } from "../../save-form/save-form-context";
import { WithSaveData } from "../../utils/save-data";
import { isOkSubmitResult, submit, SubmitResultTypes } from "../../utils/save-validation";
import SaveNavigation from "../save-navigation/save-navigation";
import classes from "./SaveButton.module.scss";
import { saveButtonTextResources } from "./save-button-resources";
import { Icon } from "adviesbox-shared";

type SaveButtonProps = {
  callBack?: () => void;
  preSaveConfirmation?: (path?: string) => Promise<void> | void;
  triggerPreSaveConfirmation?: boolean;
  name?: string;
  initialSaveResult?: SubmitResultTypes; // Voor testen alleen
  setPreviousPath?: Dispatch<SetStateAction<string | null>>;
  context: FormikContextType<any> &
  WithSaveData<any> & {
    berekenSideEffectRef?: MutableRefObject<{ asyncResult: Promise<unknown> }>;
  };
  showSaveRequest?: boolean;
  isCanceled?: boolean;
  propagateDirty?: boolean;
  label?: string;
};

const SaveButtonComponent = ({
  history,
  context,
  context: { saveData, dirty, isSubmitting },
  callBack,
  preSaveConfirmation,
  triggerPreSaveConfirmation,
  setPreviousPath,
  isCanceled,
  showSaveRequest,
  propagateDirty = true,
  label = saveButtonTextResources(showSaveRequest ? "SaveRequest" : "Save"),
  ...props
}: SaveButtonProps & RouteComponentProps): ReactElement | null => {
  const { setIsSaving, version, setSaveResult: propagateSaveResult } = useContext(SaveFormContext);
  const [lastSave, setLastSave] = useState(version);
  let buttonContent: ReactElement | null = null;
  const {
    menuInfo: { adviseurIds },
    isPageDirty,
    setPageDirty
  } = useContext(AppDataContext);
  const { seRunning, setSaveContext } = useContext(AppDataContext);
  const {
    lezenEnSchrijvenRechtenVestigingen: lezenEnSchrijvenRechten,
    getAdviseurIds,
    adviseurIds: adviseurIdsHuidigeGebruiker,
    adviseurIdsOphalen
  } = useContext(OpenDossierLogContext);
  const { params } = useRequestInit<{ vestiging: string }>();
  const [showValidatiePopup, setShowValidatiePopup] = useState(false);
  const [saveResult, setSaveResult] = useState<SubmitResultTypes>(props.initialSaveResult || "default");
  const [showNavValidatiePopup, setNavValidatiePopup] = useState(false);
  const [prevStateValues, setPrevStateValues] = useState<any>(context.initialValues);

  const scrollToTop = (scroll: boolean): void => {
    if (!scroll) return;
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  } 

  const actualSaveData = useCallback(async (preventReloadNavigation?: boolean, skipTriggerPreSaveConfirmation?: boolean): Promise<SubmitResultTypes | null> => {
    if (triggerPreSaveConfirmation && !skipTriggerPreSaveConfirmation) {
      preSaveConfirmation && (await preSaveConfirmation());
      return null;
    }

    const validationResult = await submit(saveData, context, context.berekenSideEffectRef, callBack, preventReloadNavigation);
    setSaveResult(validationResult);
    setShowValidatiePopup(!isOkSubmitResult(validationResult));
    scrollToTop(!isOkSubmitResult(validationResult));
    return validationResult;
  }, [triggerPreSaveConfirmation, preSaveConfirmation, saveData, context, callBack, setSaveResult, setShowValidatiePopup]);

  useEffect(() => {
    if (lastSave === version) return;
    setLastSave(version);
    setIsSaving(true);
    propagateSaveResult(null);
    actualSaveData()
      .then((values) => {
        propagateSaveResult(values);
      })
      .finally(() => {
        setIsSaving(false);
      })
      .catch((reason) => {
        /* eslint-disable-line no-console */ /* istanbul ignore next */ console.error(reason);
      });
  }, [lastSave, version, setIsSaving, triggerPreSaveConfirmation, preSaveConfirmation, saveData, context, callBack, setSaveResult, setShowValidatiePopup, propagateSaveResult, actualSaveData]);

  /* istanbul ignore next */
  if (adviseurIdsOphalen) {
    getAdviseurIds(params.vestiging);
  }

  /* istanbul ignore next */
  const huidigeGebruiker = adviseurIdsHuidigeGebruiker && adviseurIdsHuidigeGebruiker.map(e => e);
  const gebruikersEigenAangemaaktDossier = adviseurIds && huidigeGebruiker.includes(adviseurIds.toString());

  useEffect(() => {
    if (prevStateValues !== context.values) {
      setPrevStateValues(context.values);
      setSaveContext({
        saveDataFn: /* istanbul ignore next */ async preventReloadNavigation => {
          const result = await actualSaveData(preventReloadNavigation, true);
          return result;
        }
      });
    }
  }, [callBack, context, prevStateValues, saveData, setSaveContext, actualSaveData]);

  useEffect(() => {
    if (propagateDirty && isPageDirty !== context.dirty) {
      setPageDirty(context.dirty);
    }
  }, [propagateDirty, context.dirty, isPageDirty, setPageDirty]);

  if (isSubmitting) {
    buttonContent = (
      <span id={props.name || "save-button"} className={classes.busy}>
        {saveButtonTextResources("Busy")}
      </span>
    );
  } else if (dirty) {
    const isPlatformError = saveResult === "platformError";
    buttonContent = (
      <Button
        disabled={seRunning}
        id={props.name || "save-button"}
        variant={"primary"}
        onClick={async (): Promise<void> => {
          // trigger het opslaan
          setLastSave(version - 1);
        }}
      >
        <Icon name="save" className="mr-2"></Icon>
        {isPlatformError ? saveButtonTextResources("TryAgain") : label}
      </Button>
    );
  } else {
    buttonContent = (
      <span
        id={props.name || "save-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed",
          "pb-2": true
        })}
      >
        { showSaveRequest ? saveButtonTextResources("SaveSuccesRequest") : saveButtonTextResources("SaveSucces")}
      </span>
    );
  }

  return (
    <>
      {(!lezenEnSchrijvenRechten ||
        lezenEnSchrijvenRechten === "LezenEnSchrijven" ||
        gebruikersEigenAangemaaktDossier) && (
          <>
            {buttonContent}
            <SaveNavigation
              history={history}
              triggerPreSaveConfirmation={triggerPreSaveConfirmation}
              preSaveConfirmation={preSaveConfirmation}
              saveData={saveData}
              setValidatiePopup={setNavValidatiePopup}
              setSaveResultText={setSaveResult}
              showValidatiePopup={showNavValidatiePopup}
              callBack={callBack}
              setPreviousPath={setPreviousPath}
              isCanceled={isCanceled}
            />
            <ValidatiePopup
              infotekst={saveResult}
              show={showValidatiePopup}
              onHide={(): void => setShowValidatiePopup(false)}
            />
            <BrowserNavigation
              preventWarning={triggerPreSaveConfirmation || false}
              dirty={dirty}
              modalShow={showNavValidatiePopup}
            />
          </>
        )}
    </>
  );
};

SaveButtonComponent.displayName = "SaveButton";

export const SaveButton = withRouter(SaveButtonComponent);
