import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Icon } from "adviesbox-shared";
import BestaandeRapporten from "./bestaande-rapporten";

const RapportenMenu = (): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown 
      className="top-navigation__item"
      onToggle={(nextShow) => setIsOpen(nextShow)}
      show={isOpen}
    >
      <Dropdown.Toggle as="button" variant="link" id="naar-DOC" data-testid="naar-DOC" className="dropdown-toggle btn btn-link btn-sm">
        <Icon name="documenten" multiColor={true} />
      </Dropdown.Toggle>
      <Dropdown.Menu show={isOpen}>
        <BestaandeRapporten isOpen={isOpen} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

RapportenMenu.displayName = "RapportenMenu";

export default RapportenMenu; 