import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { Rapport } from "../../../.generated/rapportage/rapportagetypes";

export const bestaandeRapporten = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  signal?: AbortSignal
): Promise<Rapport[] | null> => {
  const url = `${settings.rapportageOrigin}/Advies/${adviesdossierId}/Rapporten`;
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    signal
  });

  if (!rsp.ok) {
    return null;
  }

  const jsonResult = await rsp.json();

  if (!jsonResult.isValid) {
    return null;
  }

  return jsonResult.rapporten as Rapport[];
};


export const downloadRapport = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  rapportId: string
): Promise<{ blob: Blob; filename: string } | null> => {
  const url = `${settings.rapportageOrigin}/Advies/${adviesdossierId}/Rapporten/${rapportId}/Download`;
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    }
  });

  if (!rsp.ok) {
    return null;
  }

  const contentDisposition = rsp.headers.get("content-disposition");
  const filenameMatch = contentDisposition?.match(/filename="([^"]*)"/) || contentDisposition?.match(/filename\*=UTF-8''([^;]*)/);
  const filename = filenameMatch ? decodeURIComponent(filenameMatch[1]) : "rapport.pdf";

  const blobResult = await rsp.blob();

  if (!blobResult) {
    return null;
  }

  return { blob: blobResult, filename };
};