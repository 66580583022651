/* istanbul ignore file */
import React, { ReactElement } from "react";
import { ListGroup } from "react-bootstrap";
import { DashboardTekort, ControleGewenstInkomenOptions } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../../shared/generic-parts/klantnaam/schema";
import { bedragFormat } from "../../../shared/utils/currency";
import { hasValue, getNaam } from "../../../shared/utils/helpers";
import { UitgangspuntenType } from "../../infra/dashboard-types";
import { Icon, useFeature } from "adviesbox-shared";
import "./tekort.scss";

type TekortProps = {
  tekort: DashboardTekort;
  uitgangspunten: UitgangspuntenType;
  beidenTonen?: boolean;
  aanvrager: KlantnaamType;
  partner: KlantnaamType | null;
  header: string; // New prop for the existing header (e.g., 'WW' or 'AO')
};

type TekortItemProps = {
  label: string;
  value: number | null;
  tooltip: string;
  type: "Hypotheek" | "Inkomen";
  aanvrager2?: boolean;
  perMonth?: boolean;
};

const TekortItem = ({ label, value, tooltip, aanvrager2, type, perMonth = false }: TekortItemProps): ReactElement => {
  const featureNewDashboard = useFeature("FeatureNewDashboard");
  return (
    <ListGroup.Item className="tekort__item">
      {featureNewDashboard && (
        <div className="row" title={tooltip} data-testid={`${type}-${label}`}>
          <div className="col-1 d-flex align-items-center">
            <Icon
              name={"menumedewerkers"}
              iconSize={"l"}
              className={`tekort__icon tekort__icon--${aanvrager2 ? "aanvrager_2" : "aanvrager"}`}
            />
          </div>
          <div className="col-10 d-flex align-items-center tekort__text">
            {hasValue(value)
              ? `${bedragFormat(perMonth ? value / 12 : value, 0)}${perMonth ? " p.m." : ""}`
              : bedragFormat(0, 0)}
          </div>
        </div>
      )}
      {!featureNewDashboard && (
        <>
          {label}:{" "}
          {hasValue(value) ? `${bedragFormat(perMonth ? value / 12 : value, 0)}${perMonth ? " p.m." : ""}` : "0 p.m."}
        </>
      )}
    </ListGroup.Item>
  );
};

export const Tekort = ({
  tekort,
  uitgangspunten,
  beidenTonen,
  aanvrager,
  partner,
  header
}: TekortProps): ReactElement => {
  const hypotheekBedragenTonen = uitgangspunten.controleHypotheeknorm?.controleerMaximaleHypotheek;
  const inkomensBedragenTonen =
    uitgangspunten.controleHypotheeknorm?.controleMaandlast ||
    uitgangspunten.controleGewenstInkomen?.controleGewenstInkomen !== ControleGewenstInkomenOptions.Geen;

  const aanvragerTonen = uitgangspunten.scenarioVanKlantIds.includes(aanvrager.klantId);
  const partnerTonen = partner && uitgangspunten.scenarioVanKlantIds.includes(partner.klantId);
  const beideTonen = beidenTonen && uitgangspunten.scenarioVanKlantIds.length === 2;

  const tekortType = hypotheekBedragenTonen ? "Tekort op hypotheek" : "Tekort op inkomen";
  const tooltip = `${header} - ${tekortType}`;

  return (
    <div className="tekort">
      <ListGroup variant="flush" className="tekort__container" data-testid="tekort-testid" title={tooltip}>
        {hypotheekBedragenTonen && (
          <>
            {aanvragerTonen && (
              <TekortItem
                label="Aanvrager"
                type="Hypotheek"
                tooltip={getNaam(aanvrager, "Aanvrager")}
                value={tekort.tekortHypotheek1Bedrag}
              />
            )}
            {partnerTonen && (
              <TekortItem
                label="Aanvrager 2"
                type="Hypotheek"
                aanvrager2={true}
                tooltip={getNaam(partner, "Aanvrager 2")}
                value={tekort.tekortHypotheek2Bedrag}
              />
            )}
            {beideTonen && (
              <TekortItem type="Hypotheek" label="Beiden" tooltip="Beiden" value={tekort.tekortHypotheekBeideBedrag} />
            )}
          </>
        )}
        {inkomensBedragenTonen && (
          <>
            {aanvragerTonen && (
              <TekortItem
                label="Aanvrager"
                type="Inkomen"
                tooltip={getNaam(aanvrager, "Aanvrager")}
                value={tekort.tekortInkomen1Bedrag}
                perMonth
              />
            )}
            {partnerTonen && (
              <TekortItem
                label="Aanvrager 2"
                type="Inkomen"
                aanvrager2={true}
                tooltip={getNaam(partner, "Aanvrager 2")}
                value={tekort.tekortInkomen2Bedrag}
                perMonth
              />
            )}
            {beideTonen && (
              <TekortItem
                type="Inkomen"
                label="Beiden"
                tooltip="Beiden"
                value={tekort.tekortInkomenBeideBedrag}
                perMonth
              />
            )}
          </>
        )}
      </ListGroup>
    </div>
  );
};
