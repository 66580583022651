import React, { ReactElement, useState, useEffect, useCallback, useContext } from "react";

import {
  CardWrapper,
  PageLoading,
  AdviesBoxColumn,
  createSpanWithId,
  DataGrid,
  SubscribeNotifyButton,
  useRequestInit
} from "adviesbox-shared";
import { RapportageState, RapportageProps } from "./infra/rapportage-schema";

import { FormikContextType } from "formik";
import { Card } from "@material-ui/core";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { rapportageElementSchema } from "../shared/components/rapportage/rapportage-samenstellen-schema";
import { SaveButton } from "../shared/components/save-button/save-button";

import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import RapportageDetails from "./rapportage-details";
import { WithSaveData } from "../shared/utils/save-data";
import { GenereerRapport } from "../.generated/foundation/foundationtypes";
import { useParams } from "react-router-dom";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";

const getRapportageKolommen = (): AdviesBoxColumn[] => {
  return [
    {
      Header: "Naam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, `${c.original.ParentHoodstukken.map((_: any) => `- `)}${c.original.naam}`)
    },
    { Header: "", Cell: (c): ReactElement => createSpanWithId(c.index, 0, null) }
  ];
};

const Rapportage = (props: FormikContextType<RapportageState> & RapportageProps): ReactElement => {
  const { isSubmitting, values, dirty, setIsDirty } = props;
  const { elementen, filteredElementen } = values;
  const paramContext = useParams<RouteParams>();
  const { settings, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { userDetails } = useContext(UserDetailsContext);

  const selectedState = useState(0);
  const [selected] = selectedState;
  const selectedElementIndex = elementen.findIndex(x => x.code === filteredElementen[selected].code);

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);

  const getRequestInit = useCallback(
    (correlationId: string): RequestInit => {
      const genereerRapport: GenereerRapport = {
        adviesDossierId: paramContext.adviesdossier,
        voorstelId: props.voorstel,
        medewerkerId: userDetails?.medewerkerId,
        rapportStructuurId: values.structuurId
      };

      const formData = new FormData();
      formData.append("JsonValues", JSON.stringify(genereerRapport));

      return {
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          vestigingId: paramContext.vestiging,
          correlationId: correlationId
        },
        method: "POST",
        body: formData
      };
    },
    [values, settings, user, userDetails, paramContext, props.voorstel]
  );

  /* istanbul ignore next */
  const requestInitNieuweAanvraagHypotheekNieuweVersie = useCallback(
    (correlationId: string): RequestInit => {
      return getRequestInit(correlationId);
    },
    [getRequestInit]
  );

  return (
    <div className="d-flex content_wrapper">
      <div className="content">
        {/* istanbul ignore next */ isSubmitting && <PageLoading />}
        <div className="save-btn-position">
          <div className="button-container">
            <div>
              <SubscribeNotifyButton
                context={props}
                icon={null}
                name="genereer-rapport"
                buttonText="Rapport genereren"
                createCommandRequest={requestInitNieuweAanvraagHypotheekNieuweVersie}
                url={`${settings.foundationOrigin}/FrontBus/genereer-rapport`}
                alwaysShow={true}
              />
            </div>
            <SaveButton context={props} />
          </div>
        </div>
        <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={1}>
          <div className="p-1 w-xl-100 w-lg-100 w-md-50 w-50">
            <Card>
              <DataGrid
                masterDetail={true}
                rowCaption="Rapportage elementen"
                columns={getRapportageKolommen()}
                name="filteredElementen"
                rowSelected={selectedState}
              />
            </Card>
          </div>
        </CardWrapper>
        <RapportageDetails selected={selectedElementIndex} />
        <DevDebug />
      </div>
    </div>
  );
};

export default withAdviesboxFormik<RapportageProps & WithSaveData<RapportageState>, RapportageState>({
  mapPropsToValues: (e: RapportageProps): RapportageState => {
    const { setIsDirty, ...state } = e;
    return state;
  },
  validationSchema: rapportageElementSchema
})(Rapportage);
