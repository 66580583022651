import React, { ReactElement } from "react";
import { Card, LabeledTextInput } from "adviesbox-shared";
import { connect, useFormikContext } from "formik";
import { FlattendElementType, RapportageState } from "./infra/rapportage-schema";

type Props = { selected: number };

const RapportageDetails = ({ selected }: Props): ReactElement => {
  const formik = useFormikContext<RapportageState>();
  const elementen = formik.values.elementen;
  const selectedElement = elementen[selected];
  const tekstblokkenIndexen: number[] = [];
  elementen.forEach((element, index) => {
    if (element.soortElement === "Tekstblok" && element.ParentCode === selectedElement.code) {
      tekstblokkenIndexen.push(index);
    }
  });

  return (
    <div className="d-flex flex-wrap flex-row flex-grow-1">
      <div className="col-12 col-md-6">
        <Card title={selectedElement?.naam}>
          {tekstblokkenIndexen.length < 1 && "Geen aanpasbare beschikbaar"}
          {tekstblokkenIndexen.length > 0 &&
            tekstblokkenIndexen?.map(x => (
              <LabeledTextInput
                key={`elementen-${x}-inhoud`}
                name={`elementen[${x}.inhoud`}
                fieldSize="grid-cell"
                caption={elementen[x].naam}
                textarea={3}
                readOnly={!elementen[x].perAdviesAanpasbaar ?? true}
              />
            ))}
        </Card>
      </div>
    </div>
  );
};

export default connect<Props, FlattendElementType>(RapportageDetails);
