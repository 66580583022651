import React, { ReactElement } from "react";
import {
  withErrorBoundary,
  ErrorBoundary,
  SettingsType,
  PageLoading,
  ErrorPage,
  InvalidAdviesboxResultErrorPage
} from "adviesbox-shared";
import Rapportage from "./rapportage";

import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { mapDlTargetToRapportageUiField, mapRapportageDlToUi } from "./infra/map-rapportage-dl-2-ui";
import { mapRapportageToDl } from "./infra/map-rapportage-ui-2-dl";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
  structuur: string;
};

export type RapportageDataProps = {
  voorstel: string;
  setIsDirty: (selected: boolean) => void;
};

const RapportageData = ({ voorstel, setIsDirty }: RapportageDataProps): ReactElement => {
  const createUrl = (s: SettingsType, p: RouteParams): string => {
    if (voorstel === "Huidig") {
      return `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/RapportSamenstellen/Structuren${p.structuur}`;
    } else {
      return `${s.klantdossiersFormsOrigin}/Voorstellen/${voorstel}/RapportSamenstellen/Structuren${p.structuur}`;
    }
  };

  /* istanbul ignore next */
  const saveUrl = (s: SettingsType, p: RouteParams): string => {
    return `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/RapportSamenstellen/Structuren${p.structuur}`;
  };

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    /* istanbul ignore next */ p => p.structuur,
    mapRapportageDlToUi,
    mapRapportageToDl,
    mapDlTargetToRapportageUiField,
    undefined,
    saveUrl
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <ErrorBoundary>
      <Rapportage
        {...data}
        setIsDirty={setIsDirty}
        voorstel={voorstel === "Huidig" ? null : voorstel}
        saveData={saveData}
      />
    </ErrorBoundary>
  );
};

RapportageData.displayName = "RapportageAjax";

export default withErrorBoundary(RapportageData);
