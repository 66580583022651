import {
  AuthContext,
  FileUpload,
  hasJSONResponse,
  hasNoRoleIsw,
  Icon,
  ModalButton,
  SettingsContext,
  useRequestInit,
  FormFirstFocus,
  insightsReactPlugin,
  SupportMenu,
  NotificatiesMenu,
  Notificaties2Menu,
  useFeature,
  AdviseurMenu,
  ZoekschermHeader,
  ZoekschermTopNavbar,
  ZoekschermHeaderColumn,
  ZoekschermItem,
  ZoekschermHeading,
  ZoekschermBody,
  SettingsType,
  ZoekschermProductSelect,
  SupportTicketModalAjax,
  SupportRemoteAssistanceModal,
  InvalidAdviesboxResultErrorPage
} from "adviesbox-shared";
import { RouteParams } from "adviesbox-shared/utils/types";
import classnames from "classnames";
import { Form } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { AboxImportResult, HbxImportResult } from "../.generated/klantdossiers-conversie/klantdossiers-conversietypes";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { zoekschermSchema, ZoekschermState } from "./infra/zoekscherm-schema";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import NieuweKlantModal from "./nieuwe-klant-modal/nieuwe-klant-modal";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";
import { getZoekenTextResources } from "./infra/zoeken-resources";
import ExternekoppelingenDossiers from "./externekoppelingen-dossiers/externekoppelingen-dossiers";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import NewLogo from "../assets/new-brand-logo.svg";
import { useParams } from "react-router-dom";
import "./zoekscherm.scss";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(true);
  const [showExterneDossiers, setShowExterneDossiers] = useState(false);
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const { data: softwareKoppelingData, loading: softwareKoppelingLoading } = useInstellingenBeheerPartijenData(
    "SoftwareKoppelingen"
  );
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);
  const { params } = useRequestInit();
  const featureNotificaties2 = useFeature("FeatureNotificaties2");

  const toonExterneDossiers: boolean =
    (!softwareKoppelingLoading &&
      softwareKoppelingData?.softwareKoppelingen?.partijen &&
      softwareKoppelingData.softwareKoppelingen.partijen.filter(x => x.actief === true && x.isImportkoppeling).length >
        0) ??
    false;

  /*istanbul ignore next*/
  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  /*istanbul ignore next*/
  const toggleShowExterneDossiers = (): void => {
    setShowExterneDossiers(!showExterneDossiers);
  };
  const { user } = useContext(AuthContext);
  const settings = useContext<SettingsType>(SettingsContext);

  const isIswMedewerker = !hasNoRoleIsw(user);
  const requestContext = useRequestInit();
  const { vestiging: vestigingId } = useParams<RouteParams>();

  /*istanbul ignore next*/
  const handleResult = (result: AboxImportResult | HbxImportResult): void => {
    if (!result.isValid) {
      let validationResults = "";
      if (result.validationResults) {
        result.validationResults.forEach(element => {
          validationResults = `${validationResults} ${element.code}(${element.target}):${element.message}`;
        });
      }
      alert(`Er ging iets fout: ${result.message} ${validationResults}`);
    }

    // redirect window to new adviesdossier
    if (!result.adviesdossierIds || !result.adviesdossierIds.length)
      alert(getZoekenTextResources("GeenAdviesdossierNaImport"));

    const newLocation =
      window.location.origin +
      `/vestiging/${vestigingId}/adviesdossier/${(result?.adviesdossierIds || [])[0]}/personalia`;

    window.location.assign(newLocation);
  };

  /*istanbul ignore next*/
  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }
  
  return (
    <FormFirstFocus>
      <Form>
        <ZoekschermHeader>
          <ZoekschermTopNavbar>
            <ZoekschermHeaderColumn alignRight={false}>
              <ZoekschermProductSelect currentApp="ADV" logo={NewLogo} />
            </ZoekschermHeaderColumn>
            <ZoekschermHeaderColumn alignRight={true}>
              <ZoekschermItem>
                <SupportMenu
                  showMeldingMaken={/*istanbul ignore next*/ () => setShowMeldingMaken(true)}
                  showRemoteAssistance={/* istanbul ignore next */ () => setShowRemoteAssistance(true)}
                  classes={{
                    iconblack: classes.iconblack,
                    iconpadding: classes.iconpadding
                  }}
                />
              </ZoekschermItem>
              <ZoekschermItem>
                {!featureNotificaties2 && <NotificatiesMenu />}
                {featureNotificaties2 && <Notificaties2Menu />}
              </ZoekschermItem>
              <ZoekschermItem>
                <AdviseurMenu />
              </ZoekschermItem>
            </ZoekschermHeaderColumn>
            <ZoekschermHeading heading="Adviesbox Advies"></ZoekschermHeading>
            <Zoeken />
          </ZoekschermTopNavbar>
          <div
            id="nieuwe_klant_button"
            className={classnames("pt-2 d-flex justify-content-center w-100 fileupload_no_styling", classes.modalbtn)}
          >
            <ModalButton
              validate={false}
              parent="nieuweKlant"
              className="btn-primary"
              content={<div>+ Nieuwe klant</div>}
            >
              <NieuweKlantModal />
            </ModalButton>

            {/* upload functionaliteit abox */}
            {isIswMedewerker && (
              <>
                &nbsp;
                <FileUpload
                  name="aboxUpload"
                  updateRef={
                    /*istanbul ignore next*/
                    async (files, name) => {
                      if (!files.length) {
                        alert(getZoekenTextResources("GeenFileGeselecteerdVoorImport"));
                        return;
                      }

                      const formData = new FormData();
                      formData.append("uploadedAbox", files[0], name);

                      const response = await fetch(`${settings.klantdossiersConversieOrigin}/Abox/upload`, {
                        ...requestContext.requestInit,
                        headers: { ...requestContext.requestInit.headers },
                        method: "POST",
                        body: formData
                      });

                      if (response.ok && hasJSONResponse(response)) {
                        const result: AboxImportResult = await response.json();
                        handleResult(result);
                      } else {
                        alert(getZoekenTextResources("FoutBijImport"));
                      }
                    }
                  }
                  multipleFiles={false}
                  variant={"AlleBestanden"}
                  buttonText={"+ Abox importeren [ISW functionaliteit]"}
                  //filesref={filesRef}
                  hideFormats
                  hideIcon
                  allowExtensions={[".abox"]}
                ></FileUpload>
              </>
            )}

            <>
              &nbsp;
              <FileUpload
                name="hbxUpload"
                updateRef={
                  /*istanbul ignore next*/
                  async (files, name) => {
                    if (!files.length) {
                      alert(getZoekenTextResources("GeenFileGeselecteerdVoorImport"));
                      return;
                    }

                    const formData = new FormData();
                    formData.append("uploadedhbx", files[0], name);

                    const response = await fetch(
                      `${settings.klantdossiersConversieOrigin}/HBX/upload?adviseurOverschrijvenMetIngelogdeGebruiker=true`,
                      {
                        ...requestContext.requestInit,
                        headers: { ...requestContext.requestInit.headers },
                        method: "POST",
                        body: formData
                      }
                    );

                    if (response.ok && hasJSONResponse(response)) {
                      const result: HbxImportResult = await response.json();
                      handleResult(result);
                    } else {
                      alert(getZoekenTextResources("FoutBijImport"));
                    }
                  }
                }
                multipleFiles={false}
                variant={"AlleBestanden"}
                buttonText={"+ Advies (hbx) inlezen"}
                //filesref={filesRef}
                hideFormats
                hideIcon
                allowExtensions={[".hbx"]}
              ></FileUpload>
            </>
          </div>
        </ZoekschermHeader>
        <ZoekschermBody>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                  <button
                    type="button"
                    onClick={toggleShowDossiers}
                    className={`${classes.clickable} d-flex flex-row flex-wrap`}
                    id="toggle-show-dossiers"
                  >
                    <h2 className={"m-0 align-self-center"}>Mijn laatst geopende dossiers</h2>
                    <div className={`${classes.icon} ml-auto`}>
                      <>
                        {!showDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                        {showDossiers && (
                          <div className={classes.chevron_up}>
                            <Icon name="chevron" alt="pijl-omhoog" />
                          </div>
                        )}
                      </>
                    </div>
                    <div className={classes.dossiers} onClick={/* istanbul ignore next */ e => e.stopPropagation()}>
                      {showDossiers && <LaatsteDossiers showDossiers={showDossiers} />}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* TODO: Voor nu alleen in development en test zichtbaar. Als import volledig is gebouwd, de volgende regel verwijderen zodat e.e.a. ook in ACC en PROD beschikbaar wordt. */}
          {settings.externeKoppelingenAvailable === true && toonExterneDossiers && (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                    <div
                      onClick={toggleShowExterneDossiers}
                      className={`${classes.clickable} d-flex flex-row flex-wrap`}
                      id="toggle-show-externe-dossiers"
                      data-testid="toggle-show-externe-dossiers"
                    >
                      <h2 className={"m-0 align-self-center"}>Externe dossiers wachtend op import</h2>
                      <div className={`${classes.icon} ml-auto`}>
                        <>
                          {!showExterneDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                          {showExterneDossiers && (
                            <div className={classes.chevron_up}>
                              <Icon name="chevron" alt="pijl-omhoog" />
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                    {showExterneDossiers && <ExternekoppelingenDossiers showExterneDossiers={showExterneDossiers} />}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* istanbul ignore next */ showMeldingmaken && user && (
            <SupportTicketModalAjax
              adviesdossierId={params.adviesdossier ?? null}
              user={user}
              closeModal={() => setShowMeldingMaken(false)}
              showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
              vestigingId={params.vestiging}
            />
          )}
          {/* istanbul ignore next */ showRemoteAssistance && (
            <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
          )}
        </ZoekschermBody>
      </Form>
    </FormFirstFocus>
  );
};

Zoekscherm.displayName = "Zoekscherm";

export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema
})(withAITracking(insightsReactPlugin, Zoekscherm));
