import { MotiveringOnderwerpsoort } from "../.generated/forms/formstypes";

export const determineTitleScherm = (type: MotiveringOnderwerpsoort): string => {
  switch (type) {
    case MotiveringOnderwerpsoort.Algemeen:
      return "Algemeen";
    case MotiveringOnderwerpsoort.InbrengEigenGeld:
      return "Eigen Geld";
    case MotiveringOnderwerpsoort.Pensioenvoorziening:
      return "Pensioen";
    case MotiveringOnderwerpsoort.Risicoprofiel:
      return "Risicoprofiel";
    case MotiveringOnderwerpsoort.HoogteOpbouwDoelvermogen:
    case MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen:
      return "Opbouw doelvermogen";
    case MotiveringOnderwerpsoort.AfdekkenWW:
      return "Werkloosheid";
    case MotiveringOnderwerpsoort.AfdekkenAOWW:
      return "Arbeidsongeschiktheid";
    case MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico:
      return "Overlijden";
    case MotiveringOnderwerpsoort.Geldverstrekker:
      return "Geldverstrekker";
    case MotiveringOnderwerpsoort.Rentevastperiode:
      return "Rentevastperiode";
    case MotiveringOnderwerpsoort.HypotheekAflossen:
      return "Aflossingsvorm";
    case MotiveringOnderwerpsoort.FiscaleAftrek:
      return "Fiscale aftrek";
    case MotiveringOnderwerpsoort.DubbeleWoonlasten:
      return "Dubbele woonlasten";
    case MotiveringOnderwerpsoort.VerantwoordeWoonlasten:
      return "Verantwoorde woonlasten";
    case MotiveringOnderwerpsoort.Energie:
      return "Verduurzaming";
    default:
      return "Geen titel gevonden voor geselecteerde type";
  }
};

export const determineTitleDubbeleWoonlastenCard = (type: MotiveringOnderwerpsoort): string | null => {
  switch (type) {
    case MotiveringOnderwerpsoort.HoogteOpbouwDoelvermogen:
    case MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen:
      return "Opbouw doelvermogen";
    case MotiveringOnderwerpsoort.VerantwoordeWoonlasten:
      return "Verantwoorde woonlasten";
    case MotiveringOnderwerpsoort.InbrengEigenGeld:
      return "Inbreng eigen geld";
    case MotiveringOnderwerpsoort.DubbeleWoonlasten:
      return "Dubbele woonlasten";
    case MotiveringOnderwerpsoort.FiscaleAftrek:
      return "Fiscale aftrek";
    case MotiveringOnderwerpsoort.HypotheekAflossen:
      return "Aflossingsvorm";
    case MotiveringOnderwerpsoort.Rentevastperiode:
      return "Rentevastperiode";
    default:
      return null;
  }
};

export const determineAanvragerHeader = (type: MotiveringOnderwerpsoort): boolean => {
  switch (type) {
    case MotiveringOnderwerpsoort.Algemeen:
    case MotiveringOnderwerpsoort.Energie:
    case MotiveringOnderwerpsoort.Verduurzaming:
    case MotiveringOnderwerpsoort.VerantwoordeWoonlasten:
    case MotiveringOnderwerpsoort.DubbeleWoonlasten:
    case MotiveringOnderwerpsoort.InbrengEigenGeld:
    case MotiveringOnderwerpsoort.FiscaleAftrek:
    case MotiveringOnderwerpsoort.HypotheekAflossen:
    case MotiveringOnderwerpsoort.Rentevastperiode:
    case MotiveringOnderwerpsoort.Geldverstrekker:
    case MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico:
    case MotiveringOnderwerpsoort.AfdekkenAOWW:
    case MotiveringOnderwerpsoort.AfdekkenWW:
    case MotiveringOnderwerpsoort.Pensioenvoorziening:
    case MotiveringOnderwerpsoort.Risicoprofiel:
    case MotiveringOnderwerpsoort.HoogteOpbouwDoelvermogen:
    case MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen:
      return true;
    default:
      return false;
  }
};
